import { useQuery, useMutation } from "react-query";
import { COLLATERAL_DASHBOARD } from "services";

export const useDashobardCollateral = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return COLLATERAL_DASHBOARD.getDashboard({ ...params }, signal);
    },
    ...options,
  });
};

export const useDashobardCollateralTable = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return COLLATERAL_DASHBOARD.getDashboardTable({ ...params }, signal);
    },
    ...options,
  });
};

export const useDashobardCollateralTableTallySheet = (keys, options) => {
  return useQuery({
    queryKey: keys,
    queryFn: ({ signal, queryKey }) => {
      const params = queryKey[1];
      return COLLATERAL_DASHBOARD.getDashboardTallySheet({ ...params }, signal);
    },
    ...options,
  });
}

import { Button, Table, TitleText } from 'components'
import { ButtonBackConfirm } from 'components/HookForm';
import { AnimatePresence, motion } from 'framer-motion';
import { useDashobardCollateralTableTallySheet } from 'hooks/collateralDashboard';
import React, { useCallback, useMemo, useState } from 'react'
import { BsArrowLeftShort, BsEye } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { useNavigate, useSearchParams } from 'react-router-dom';

function TallySheet() {

    // Add this near the top of your component with other hooks
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  
  // You can get the parameters like this
  const provinceId = searchParams.get('province_id');
  const cityId = searchParams.get('city_id');

  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(1)
  const [isModalOpen, setModalOpen] = useState(false);
  const [idTallySheet, setIdTallySheet] = useState({})


    const { data: tallySheetTable, isLoading: tallySheetTableLoading } = useDashobardCollateralTableTallySheet(["detail Tally sheet", {
        province_id: provinceId,
        city_id: cityId,
        limit,
        page
    }]); 

    const treeColumns = useMemo(() => {
        return [
            {
                id: "no",
                title: "No",
                dataIndex: "no",
                render: (value, data, index) => {
                const no =
                    parseInt(page) * parseInt(limit) - parseInt(limit) + index + 1;
                return no + ".";
                },
            },
            {
                id: "tree_number",
                title: "No Pohon",
                dataIndex: "tree_number",
                sortable: true,
                render: (value, data, index) => value ?? "-",
            },
            {
                id: "name",
                title: "Nama Pohon",
                dataIndex: "name",
                sortable: true,
                render: (value, data, index) => (value ? `${value}` : "-"),
            },
            {
                id: "area",
                title: "Ukuran",
                dataIndex: "area",
                sortable: true,
                render: (value, data, index) => (value ? `${value} cm` : "-"),
            },
            {
                id: "debtor_name",
                title: "Nama Debitur",
                dataIndex: "debtor_name",
                sortable: true,
                render: (value, data, index) => (data?.agreement_agency_collateral ? `${data?.agreement_agency_collateral?.name}` : "-"),
            },
            {
                id: "price",
                title: "Nilai Agunan",
                dataIndex: "price",
                sortable: true,
                render: (value, data, index) =>
                value
                    ? new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                    }).format(value)
                    : "-",
            },
            {
                id: "action",
                title: "aksi",
                dataIndex: "value",
                sortable: true,
                render: (value, data, index) => (
                <div>
                    {
                        <BsEye
                            className={`text-gray-600 cursor-pointer`}
                            onClick={() => {
                                setIdTallySheet(data)
                                setModalOpen(true)
                            }}
                        />
                    }
                </div>
                ),
            }
        ]
    }, [])

    const onChangePage = useCallback((val) => setPage(val), []);
    const onChangeRowsPerPage = useCallback((val) => setLimit(val), []);

  return (
    <React.Fragment>
        <div className='mb-4 flex items-center cursor-pointer w-fit' onClick={() => navigate(-1)}>
            <BsArrowLeftShort className="text-gray-700" size={20} />
            <TitleText className="text-gray-700 flex-1">Kembali</TitleText>
        </div>
        <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
            <div className="card-body p-6">
                <div className="space-y-6">
                    <div className="flex space-x-4 items-center">
                        <TitleText className="flex-1">
                            {`Daftar Pohon - `}
                        </TitleText>
                    </div>
                    <div className="">
                        <Table
                            bordered
                            layout="fixed"
                            className="mb-4"
                            columns={ treeColumns }
                            dataSource={tallySheetTable?.result?.data ?? []}
                            isLoading={tallySheetTableLoading}
                            onChangePage={onChangePage}
                            onChangeRowsPerPage={onChangeRowsPerPage}
                            pagination={tallySheetTable?.paginator ?? {}}
                        />
                        <ModalDetail open={isModalOpen} onClose={(val) => setModalOpen(val)} tallySheet={idTallySheet} />
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
  )
}

const ModalDetail = ({ open, onClose, tallySheet = {} }) => {
    const {tree_number, name, price, size, agreement_agency_collateral = {}} = tallySheet
    const { address, name: name_debtor, latitude, longitude, province_name } = agreement_agency_collateral
    return (
        <AnimatePresence mode="wait">
            {open && (
                <div className="flex items-center justify-center fixed inset-0 w-screen h-screen bg-black/70 z-20">
                    <motion.div
                        key="modal-confirmation"
                        className="bg-white rounded-xl"
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                    >
                        <div className="flex flex-col p-8 space-y-8">
                            <div className="flex-1 flex items-center justify-between">
                                <div className="text-gray-900 text-lg font-semibold">Detail</div>
                                <CgClose
                                    className="text-xl cursor-pointer"
                                    onClick={() => onClose(false)}
                                />
                            </div>
                            <div className='grid grid-cols-3 gap-4'>
                                <div className="space-y-2 flex-1">
                                    <div className="text-gray-900 font-semibold">No Pohon</div>
                                    <div className="text-gray-900">{tree_number ?? "-"}</div>
                                </div>
                                <div className="space-y-2 flex-1">
                                    <div className="text-gray-900 font-semibold">Nama Pohon</div>
                                    <div className="text-gray-900">{name ?? "-"}</div>
                                </div>
                                <div className="space-y-2 flex-1">
                                    <div className="text-gray-900 font-semibold">Ukuran</div>
                                    <div className="text-gray-900">{size ? `${size} cm` : "-"}</div>
                                </div>
                                <div className="space-y-2 flex-1">
                                    <div className="text-gray-900 font-semibold">Nilai Agunan</div>
                                    <div className="text-gray-900">
                                        {price
                                            ? new Intl.NumberFormat("id-ID", {
                                                style: "currency",
                                                currency: "IDR",
                                            }).format(price)
                                            : "-"}
                                    </div>
                                </div>
                                <div className="space-y-2 flex-1">
                                    <div className="text-gray-900 font-semibold">Debitur</div>
                                    <div className="text-gray-900">
                                        {name_debtor ?? "-"}
                                    </div>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <div className="text-gray-900 font-semibold">Lokasi</div>
                                <div className="text-gray-900">{address ?? "-"}</div>
                            </div>
                            <div className='flex space-x-6'>
                                <div className="space-y-2 flex-1">
                                    <div className="text-gray-900 font-semibold">Provinsi</div>
                                    <div className="text-gray-900">{province_name ?? "-"}</div>
                                </div>
                                <div className="space-y-2 flex-1">
                                    <div className="text-gray-900 font-semibold">Latitude</div>
                                    <div className="text-gray-900">{latitude ?? "-"}</div>
                                </div>
                                <div className="space-y-2 flex-1">
                                    <div className="text-gray-900 font-semibold">Longitude</div>
                                    <div className="text-gray-900">{longitude ?? "-"}</div>
                                </div>
                                <div className="space-y-2 flex-1">
                                    <div className="text-gray-900 font-semibold">Link Koordinat Lokasi Gmaps</div>
                                    <a
                                        href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
                                        target="_blank" rel="noreferrer" className="text-blue-500 underline"
                                    > Gmaps </a>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    )
}

export default TallySheet